@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	/* display: flex; */
	padding-bottom: 40px;
	/* width: 400px; */
	display: grid;
    grid-template-columns: 2fr 1fr;
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	color: var(--secondary-color);
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
}

.work-duration {
	font-size: 12px;
	color: var(--tertiary-color);
}

.work-details {
	width: 60%;
}