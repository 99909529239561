.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	top: 4vh;
}

@media screen and (max-width: 750px){
	.articles-logo {
		transform: translateX(-50%);
		top: 9vh;
		left: 50%;
		z-index: 999;
	}
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 20px;
	border-left: 2px solid #f4f4f5;
	/* width: 100%; */
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}
