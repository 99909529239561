@import "../../data/styles.css";

.read-article-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.read-article-logo {
	display: flex;
	position: fixed;

	top: 4vh;
}

@media screen and (max-width: 750px) {
	.read-article-logo {
		transform: translateX(-50%);
		top: 9vh;
		left: 50%;
		z-index: 999;
	}
}

.read-article-container {
	display: flex;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.read-article-back {
	padding-top: 68px;
	padding-right: 50px;
	width: 40px;
	height: 40px;
}

.read-article-back-button {
	width: 50px;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.read-article-wrapper {
	width: 80%;
	padding-top: 30px;
}

.read-article-date-container {
	border-left: 2px solid var(--quaternary-color);
	color: var(--tertiary-color);
	font-size: 16 px;
	align-items: center;
	height: 22px;
}

.read-article-date {
	font-family: var(--primary-font);
	padding-left: 15px;
}

.read-article-title {
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100% !important;
}

.read-article-body {
	padding-bottom: 50px;
	font-size: 16px;
	line-height: 30px;
	font-family: system-ui;
	color: #52525b;
}

.read-article-body img {
	max-width: 100%;
}

.read-article-body pre {
	background-color: #1f2937;
	color: #ffffff;
	padding: 10px 20px;
	border-radius: 10px;
	margin: 1rem 0;
	overflow-x: auto;
}

@media screen and (max-width: 750px){
	.read-article-container{
		display: block;
	}
	.read-article-back{
		padding-top: 0;
	}
	.read-article-wrapper{
		width: 100%;
		max-width: 100%;
	}	
}

.hljs {
	display: block;
	overflow-x: auto;
	padding: 0.5em;
	background: #23241f;
}
.hljs,
.hljs-subst,
.hljs-tag {
	color: #f8f8f2;
}
.hljs-emphasis,
.hljs-strong {
	color: #a8a8a2;
}
.hljs-bullet,
.hljs-link,
.hljs-literal,
.hljs-number,
.hljs-quote,
.hljs-regexp {
	color: #ae81ff;
}
.hljs-code,
.hljs-section,
.hljs-selector-class,
.hljs-title {
	color: #a6e22e;
}
.hljs-strong {
	font-weight: 700;
}
.hljs-emphasis {
	font-style: italic;
}
.hljs-attr,
.hljs-keyword,
.hljs-name,
.hljs-selector-tag {
	color: #f92672;
}
.hljs-attribute,
.hljs-symbol {
	color: #66d9ef;
}
.hljs-class .hljs-title,
.hljs-params {
	color: #f8f8f2;
}
.hljs-addition,
.hljs-built_in,
.hljs-builtin-name,
.hljs-selector-attr,
.hljs-selector-id,
.hljs-selector-pseudo,
.hljs-string,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
	color: #e6db74;
}
.hljs-comment,
.hljs-deletion,
.hljs-meta {
	color: #75715e;
}
